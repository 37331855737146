// extracted by mini-css-extract-plugin
export var ArtistDescription = "HenryChalfant-module--ArtistDescription--w08tM";
export var ArtistInfos = "HenryChalfant-module--ArtistInfos--0ICC0";
export var ButtonWrapper = "HenryChalfant-module--ButtonWrapper --HYA9v";
export var CardWrapper = "HenryChalfant-module--CardWrapper--CXaWT";
export var CarrouselWrapper2 = "HenryChalfant-module--CarrouselWrapper2--X6W4i";
export var Citations = "HenryChalfant-module--Citations--pqeQ+";
export var DescriptionWrapper = "HenryChalfant-module--DescriptionWrapper--KCcnc";
export var ImageWrapper = "HenryChalfant-module--ImageWrapper--6wiut";
export var LinkWrapper = "HenryChalfant-module--LinkWrapper--LrzjR";
export var MobileProtrait = "HenryChalfant-module--MobileProtrait--Z1O26";
export var More = "HenryChalfant-module--More--7B5Nq";
export var MoreButton = "HenryChalfant-module--MoreButton--09r1b";
export var NameWrapper = "HenryChalfant-module--NameWrapper--Kc-k3";
export var PdpWrapper = "HenryChalfant-module--PdpWrapper--1w1r2";
export var PhotosWrapper = "HenryChalfant-module--PhotosWrapper--GNcTq";
export var ProfilWrapper = "HenryChalfant-module--ProfilWrapper--CZMz7";
export var TitleWrapper = "HenryChalfant-module--TitleWrapper--bucKj";
export var Wrapper = "HenryChalfant-module--Wrapper--w1KQD";